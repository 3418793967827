import React from 'react';
import { Link, withRouter } from "react-router-dom";
import Nav from '../components/Nav';

function Wines(props) {
  return (
    <section id="wines">
      <Nav/>
      <div className="left wine-gallery">
      { props.wines.map( wine => <WineGridItemWithRouter wine={wine}/> ) }
      </div>
      <div className="right description">
        <p>{ props.info.wines }</p>
      </div>
    </section>
  );
}

const WineGridItemWithRouter = withRouter(WineGridItem);

function WineGridItem(props) {
  const { match } = props;
  let lng = match.params.lng;

  let id = props.wine.getId();
  let title = props.wine.get('Nome do Vinho') || 'Sem nome' ;
  let label = props.wine.get('Tipo do Vinho') || 'Vinho verde';
  let year = props.wine.get('Ano') || '2020';
  let image = props.wine.get('Imagem') || [];
  let thumb = image[0]? image[0].url : '';

  return (
    <div className="wine-gallery-item">
      <Link to={"/" + lng + "/wines/" + id} className="wine-bottle">
        <div className="wine-bottle-thumb" style={{backgroundImage: "url('" + thumb +"')"}}>
        </div>
        {/*<img alt={thumb} src={thumb}></img>*/}
        <div className="wine-info">
          <span className="wine-label">
            <span className="wine-label-baloon">
              {title}
            </span>
          </span>
          <span className="wine-label">
            <span className="wine-label-baloon">
              {label}
            </span>
          </span>
          <span className="wine-label">
            <span className="wine-label-baloon">
              {year}
            </span>
          </span>
        </div>
      </Link>
    </div>
  )
}

export default withRouter(Wines);
