import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Nav(props) {
  const { t } = useTranslation();
  const { match } = props;
  let lng = match.params.lng;
  return (
        <div className="nav">
          <Link className="brand" to="/">
            <img alt='Logo' src={props.match.path === '/:lng'? "./logo-full.png" : "./logo-small.png"}/>
          </Link>
          <ul>
            <li>
              <Link className={props.match.path.includes('about')? 'active' : ''} to={"/" + lng +"/about"}>
                {t('nav_about')}
              </Link>
            </li>
            <li>
              <Link className={props.match.path.includes('wines')? 'active' : ''} activeClassName='active' to={"/" + lng +"/wines"}>
                {t('nav_wines')}
              </Link>
            </li>
            <li>
              <Link className={props.match.path.includes('contact')? 'active' : ''} activeClassName='active' to={"/" + lng +"/contact"}>
                {t('nav_contact')}
              </Link>
            </li>
          </ul>
        </div>
  )
}

export default withRouter(Nav);