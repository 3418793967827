import React from 'react';
import Nav from '../components/Nav';
import { Link } from "react-router-dom";
/*import { Carousel } from 'react-responsive-carousel';*/
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";


class Wine extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {

    const { match } = this.props;
    let lng = match.params.lng;

    let pos;
    for (let i = 0; i < this.props.wines.length && pos === undefined; i++) {
      if(this.props.wines[i].getId() === this.props.match.params.id) {
        pos = i;
      }
    }

    let current, previous, next;
    current = this.props.wines[pos];
    previous = pos === 0? this.props.wines[this.props.wines.length - 1] : this.props.wines[pos-1];
    next = pos === this.props.wines.length - 1? this.props.wines[0] : this.props.wines[pos+1];

    let thumb = '';

    if(current) {
      let image = current.get('Imagem') || [];
      thumb = image[0]? image[0].url : '';
    }

    return (
      <section id='wine'>
        <Nav/>
        <div className='left'>
          <div className='wine-image' style={{ backgroundImage: "url('" + thumb + "')" }}>
            {/*<img alt={thumb} className='' src={thumb}/>*/}
            <Link aria-hidden={false} to={previous? '/' + lng + '/wines/' + previous.getId() : ''} className='previous' />
            <Link aria-hidden={false} to={next? '/' + lng + '/wines/' + next.getId() : ''} className='next' />
          </div>
        </div>
        <div className='right content'>
          {current? <WineContentWithRouter wine={current}/> : ''}
        </div>
      </section>
    );
  }

}

const WineContentWithRouter = withRouter(WineContent);

function WineContent(props) {
  //let year = props.wine.get('Ano') || '2020';
  //let image = props.wine.get('Imagem') || [];
  //let thumb = image[0]? image[0].url : '';
  const { t } = useTranslation();

  const { match } = props;
  let lng = match.params.lng;

  let title = props.wine.get('Nome do Vinho') || 'Sem nome';
  let label = props.wine.get('Tipo do Vinho [Extendido]') || 'Vinho verde DOC';
  let region = props.wine.get('Região') || 'Portugal';
  let description = props.wine.get('Descrição') || 'Vinho verde DOC';

  let grapes = props.wine.get('Castas') || [];
  let sugestions = props.wine.get('Sugestão de Serviço') || [];
  let haromos = props.wine.get('Harmonizações') || [];
  let year = props.wine.get('Colheita') || [];
  let degree = props.wine.get('Álcool') || [];

  return (
    <React.Fragment>
      <div className='wine-header'>
        <h1 className='wine-title'>{title}</h1>
        <h2 className='wine-subtitle'>{label}</h2>
        <h2 className='wine-subtitle'>{region}</h2>
        <Link className='wine-close close-button' to={'/' + lng + '/wines'}>
          <div class='in'>
            <div class='close-button-block'></div>
            <div class='close-button-block'></div>
          </div>
          <div class='out'>
            <div class='close-button-block'></div>
            <div class='close-button-block'></div>
          </div>
        </Link>
      </div>
      <div className='wine-section'>
        <p className='wine-section-title'>{t('wines_description')}</p>
        <p className='wine-section-description'>{description}</p>
      </div>
      <div className='wine-section bottom'>
        <p className='wine-section-title'>{t('wines_details')}</p>
        <div className='wine-section-description'>
          <div className='wine-section-col'>
            <p className='wine-section-col-title'>{t('wines_details_caste')}</p>
            {grapes.map(grape => <p className='wine-section-col-value'>{grape}</p>)}
            <p className='wine-section-col-title harvest'>{t('wines_details_harvest')}</p>
            <p className='wine-section-col-value'>{year}</p>
          </div>
          <div className='wine-section-col'>
            <p className='wine-section-col-title'>{t('wines_details_sugestion')}</p>
            {sugestions.map(sugestion => <p className='wine-section-col-value'>{sugestion}</p>)}
            <p className='wine-section-col-title alcohol'>{t('wines_details_alcool')}</p>
            <p className='wine-section-col-value'>{degree}</p>
          </div>
          <div className='wine-section-col'>
            <p className='wine-section-col-title'>{t('wines_details_harmonizations')}</p>
            {haromos.map(haromo => <p className='wine-section-col-value'>{haromo}</p>)}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Wine);
