import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Nav from '../components/Nav';
import { withTranslation } from "react-i18next";

const position = { lat: 41.102318, lng: -8.2171163}

const mapOptions = {
  gestureHandling: 'greedy',
  scrollwheel: false,
  zoomControl: false,
  disableDefaultUI: true,
  // How zoomed in you want the map to start at (always required)
  zoom: 5,

  // The latitude and longitude to center the map (always required)
  center: position,

  // How you would like to style the map. 
  // This is where you would paste any style found on Snazzy Maps.
  styles: 

[
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "saturation": 36
      },
      {
        "color": "#333333"
      },
      {
        "lightness": 40
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 17
      },
      {
        "weight": 1.2
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dedede"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 29
      },
      {
        "weight": 0.2
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 18
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [{
    "visibility": "off"
    }]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f2f2f2"
      },
      {
        "lightness": 19
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e9e9e9"
      },
      {
        "lightness": 17
      }
    ]
  }
]


};

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      body: '',
      subject: '',
    }
  }

  handleNameChange = (event) => {
    this.setState({name: event.target.value});
  }

  handleEmailChange = (event) => {
    this.setState({email: event.target.value});
  }

  handleSubjectChange = (event) => {
    this.setState({subject: event.target.value});
  }

  handleBodyChange = (event) => {
    this.setState({body: event.target.value});
  }

  render() {
    let mailLink = `mailto:info@quintadasamoca.com?cc=${this.state.email}&subject=${this.state.subject}&body=${this.state.body + '\n\n' + this.state.name }`

    const { t } = this.props;

    return (
      <section id='contact'>
        <Nav/>
        <div className='left map'>
          <ContactMapWithTranslation/>
        </div>
        <div className='right description'>
          <div className='contact-form'>
            <form>
              <input type='text' placeholder={t('contact_name').toUpperCase()} value={this.state.name} onChange={this.handleNameChange}></input>
              <input type='text' placeholder={t('contact_mail').toUpperCase()} value={this.state.email} onChange={this.handleEmailChange}></input>
              <input type='text' placeholder={t('contact_subject').toUpperCase()} value={this.state.subject} onChange={this.handleSubjectChange}></input>
              <textarea placeholder={t('contact_message').toUpperCase()} name={this.state.body} onChange={this.handleBodyChange}></textarea>
              <a className='btn' href={encodeURI(mailLink)}>{t('contact_send').toUpperCase()}</a>
              {/* <input className='btn' type='button' value="ENVIAR"></input> */}
            </form>
          </div>
          <div className='contact-info'>
            <div className='contact-details'>
              <div className='contact-address'>
                <span>Rua Nossa Sra. de Fátima,</span>
                <span>4625-143</span>
                <span>Marco de Canaveses</span>
                <span>Portugal</span>
              </div>
              <div className='contact-mail-number'>
                <span className=''>Telefone: +351 255 148 459</span>
                <span className=''>info@quintadasamoca.com</span>
              </div>
            </div>
            <div className='contact-links'>
              <a href='https://www.facebook.com/quintadasamoca' target='_bank' rel='noopener noreferrer' >Facebook</a>
            {/*
              <a href='https://www.facebook.com/quintadasamoca' target='_bank' rel='noopener noreferrer' >Instagram</a>
            */}
            </div>
            <br></br>
          </div>
        </div>
      </section>
    )
  }

}

export default withTranslation()(Contact);

const ContactMapWithTranslation = withTranslation()(ContactMap);

function ContactMap(props) {
    //let mapLink = 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJlf_cJHmZJA0RVlepfKm247E&key=AIzaSyBKVc4SRTaqxaD0XCO_EoJd8VUk7r0BNVg';
    const { t } = props;

    let containerStyle = {
      width: '100%',
      minHeight: document.body.clientWidth >= 768? 'calc(100vh - 50px)' : 'calc(80vh - 50px)'
    };

    const [setMap] = React.useState(null)
   
    const onLoad = marker => {
      console.log(marker)
    }

    return (
        <LoadScript googleMapsApiKey="AIzaSyBKVc4SRTaqxaD0XCO_EoJd8VUk7r0BNVg">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={position}
            zoom={15}
            options={mapOptions}>
            <Marker onLoad={onLoad} position={position}/>
            <div className="placecard">
              <div className="placecard-container">
                <div className="placecard-left">
                  <p className="placecard-business-name">Quinta da Samoça</p>
                  <p className="placecard-info">Rua Nossa Sra. de Fátima</p>
                  <p className="placecard-info">4625-143 - Marco Canaveses</p>
                  <a className="placecard-view-large" target="_blank" href="https://maps.google.com/maps?ll=41.102291,-8.214739&z=15&t=m&hl=en-GB&gl=US&mapclient=embed&cid=12818289803483764566" id="A_41">{t('contact_view_large')}</a>
                </div>
                <div className="placecard-right">
                  <a className="placecard-direction-link" target="_blank" href="https://maps.google.com/maps?ll=41.102291,-8.214739&z=15&t=m&hl=en-GB&gl=US&mapclient=embed&daddr=Quinta%20da%20Samo%C3%A7a%20Rua%20Nossa%20Sra.%20de%20F%C3%A1tima%204625-143%20Marco%20de%20Canaveses@41.1022906,-8.2147385" id="A_9">
                      <div className="placecard-direction-icon"></div>
                      {t('contact_show_direction')}
                  </a>
                </div>
              </div>
            </div>
          </GoogleMap>
        </LoadScript>
    );
}
