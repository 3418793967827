import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import Nav from '../components/Nav';


function About(props) {
  let aboutImages = props.info.aboutImages? ( window.innerWidth > 768? props.info.aboutImages : props.info.aboutImagesMobile ) : [];
  return (
    <section id="about">
      <Nav/>
      <div className="left gallery">
        <Carousel infiniteLoop={true} showArrows={true}>
          {aboutImages.map(image => <CarousselItems image={image} />)}
        </Carousel>
      </div>
      <div className="right description">
        <p>{props.info.about || ''}</p>
      </div>
    </section>
  );
}

function CarousselItems(props) {
  return (
    <div className="carousel-item" style={{background: 'url("' + props.image.url + '")'}}/>
  );
}

export default About;
