import React from "react"
import Home from "./containers/Home"
import About from "./containers/About"
import Wines from "./containers/Wines"
import Wine from "./containers/Wine"
import Contact from "./containers/Contact"
import i18next from "i18next"
import { withTranslation, initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Airtable from 'airtable'

import "./App.css";

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'hashDetector',

  lookup(options) {
    if(window.location.hash.includes('en')) {
      return 'en';
    } else if(window.location.hash.includes('pt')) {
      return 'pt';
    } else if(window.location.hash === '#/') {
      return undefined;
    } else {
      return 'pt';
    }
  },
});


i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init({
    detection: {
      order: ['hashDetector', 'navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
      lookupQuerystring: 'lng',
      lookupFromPathIndex: 2,
    },
    resources: {
      en: {
        translation: {
          "nav_about": "about us",
          "nav_wines": "our wines",
          "nav_contact": "contact",
          "wines_description" : "Description",
          "wines_details" : "Details",
          "wines_details_caste" : "Varieties:",
          "wines_details_sugestion" : "Suggestion:",
          "wines_details_harmonizations" : "Pairings:",
          "wines_details_harvest" : "Harvest:",
          "wines_details_alcool" : "Alcohol:",
          "contact_name" : "name",
          "contact_mail" : "mail",
          "contact_subject" : "info / orders",
          "contact_message" : "message",
          "contact_send" : "send",
          "contact_show_direction" : "Directions",
          "contact_view_large" : "View larger map"
        }
      },
      pt: {
        translation: {
          "nav_about": "sobre nós",
          "nav_wines": "os nossos vinhos",
          "nav_contact": "contactos",
          "wines_description" : "Descrição",
          "wines_details" : "Detalhes",
          "wines_details_caste" : "Castas:",
          "wines_details_sugestion" : "Sugestão de Serviço:",
          "wines_details_harmonizations" : "Harmonizações:",
          "wines_details_harvest" : "Colheita:",
          "wines_details_alcool" : "Álcool:",
          "contact_name" : "nome",
          "contact_mail" : "email",
          "contact_subject" : "info / encomendas",
          "contact_message" : "mensagem",
          "contact_send" : "enviar",
          "contact_show_direction" : "Instruções",
          "contact_view_large" : "Ver no mapa"
        }
      }
    },
    fallbackLng: "pt"
  });

class App extends React.Component {

  constructor(props) {
    super(props);
    Airtable.configure({
        endpointUrl: 'https://api.airtable.com',
        apiKey: 'keyuNjq1Pz6DpHukd'
    });
    this.state = {
      wines: [],
      info: {},
    }
  }

  componentDidMount() {
    const { i18n } = this.props;
    const lng = i18n.language.includes('en')? 'EN' : 'PT';
    const base = Airtable.base('appiM9qEteosxeTFd');
    base('Vinhos ' + lng).select({ view: 'Grid view' }).firstPage(
      (err, records) => {
        if(err) {
          console.log("Error in retrieving app data", err);
        }
        this.setState({ wines: records });
      }
    );
    base('Textos ' + lng).select({ view: 'Grid view' }).firstPage(
      (err, records) => {
        if(err) {
          console.log("Error in retrieving app data", err);
        } else {
        let info = {
          about: records[0].get('Sobre'),
          aboutImages: records[0].get('Sobre - Imagens'),
          aboutImagesMobile: records[0].get('Sobre - Imagens - Mobile'),
          wines: records[0].get('Os nossos vinhos')
        }
        this.setState({ info });
        }
      }
    );
  }

  render() {
    const { i18n } = this.props;
    const lng = i18n.language.includes('en')? 'en' : 'pt';
    return (
      <Router>
        <Switch>
          <Route path="/:lng/about">
            <About info={this.state.info}/>
          </Route>
          <Route path="/:lng/wines/:id">
            <Wine wines={this.state.wines} />
          </Route>
          <Route path="/:lng/wines">
            <Wines info={this.state.info} wines={this.state.wines} />
          </Route>
          <Route path="/:lng/contact">
            <Contact />
          </Route>
          <Route path="/:lng">
            <Home />
          </Route>
          <Route path="/">
            <Redirect to={"/" + lng} />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default withTranslation()(App);
