import React from 'react';

import Nav from '../components/Nav';

function Home() {
return (
    <section id="home">
      <Nav/>
      <div className="background"/>
    </section>
  );
}

export default Home;
